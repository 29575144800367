<template>
  <v-container>
    <v-form>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="pt-10">
              <span><b>ข้อมูลผู้เสียภาษี</b></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-2">
              <span>ชื่อ - สกุล</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.fullName }}
            </v-col>
            <v-col cols="2" class="pt-2">
              <span>เลขบัตรประจำตัวประชาชน</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.idCardNo }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>บ้านเลขที่</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.houseNo }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>หมู่</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.moo }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12"
          ><v-row>
            <v-col cols="12" class="pt-10">
              <span><b>ราคาประเมินทุนทรัพย์ของที่ดิน</b></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-2">
              <span>ประเภทที่ดิน</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landType }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>เลขที่เอกสารสิทธิ์</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.documentNo }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>สถานที่ตั้ง (หมู่/ตำบล)</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.address }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>จำนวนเนื้อที่ดิน </span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{
                `${data.areaRai} ไร่ ` +
                ` ${data.areaNgan} งาน ` +
                ` ${data.areaWah} วา`
              }}
            </v-col>
            <v-col cols="2" class="pt-2">
              <span>ลักษณะการทำประโยชน์</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.profitType }}
            </v-col>
            <v-col cols="2" class="pt-2">
              <span>ตารางวา</span>
            </v-col>
            <v-col cols="1" class="pt-2">
              {{ data.squareWah }}
            </v-col>
            <v-col cols="2">ตารางวา</v-col>
            <v-col cols="2" class="pt-2">
              <span>ราคาประเมินต่อตารางวา</span>
            </v-col>
            <v-col cols="1" class="pt-2">
              {{ data.squareWahPrice }}
            </v-col>
            <v-col cols="3" class="pt-2">บาท</v-col>
            <v-col cols="2" class="pt-2">
              <span>รวมราคาประเมินที่ดิน</span>
            </v-col>
            <v-col cols="1" class="pt-2">
              {{ data.landPrice }}
            </v-col>
            <v-col cols="2" class="pt-2">บาท</v-col>
          </v-row></v-col
        >
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-10">
          <span><b>ราคาประเมินทุนทรัพย์ของสิ่งปลูกสร้าง</b></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="pt-2">
          <span>ประเภทของสิ่งปลูกสร้าง</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingName }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>ลักษณะสิ่งปลูกสร้าง (ตึก/ไม้/ครึ่งตึกครึ่งไม้)</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingType }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>ลักษณะการทำประโยชน์</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.profitBuilding }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>ขนาดพื้นที่ สิ่งปลูกสร้าง (ตร.ม.)</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingArea }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>คิดเป็นสัดส่วนตามการใช้ประโยชน์ (ร้อยละ)</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingPercent }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>ราคาประเมิน สิ่งปลูกสร้าง ต่อ ตร.ว. </span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingAreaPrice }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>รวมราคา สิ่งปลูกสร้าง</span>
        </v-col>
        <v-col cols="1" class="pt-2">
          {{ data.buildingPrice }}
        </v-col>
        <v-col cols="3" class="pt-2">บาท</v-col>
        <v-col cols="2" class="pt-2">
          <span>อายุ สิ่งปลูกสร้าง</span>
        </v-col>
        <v-col cols="1" class="pt-2">
          {{ data.buildingAge }}
        </v-col>
        <v-col cols="3" class="pt-2">ปี</v-col>
        <v-col cols="2" class="pt-2">
          <span> ค่าเสื่อม</span>
        </v-col>
        <v-col cols="1" class="pt-2">ร้อยละ</v-col>
        <v-col cols="3" class="pt-2">
          {{ data.buildingPercent }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span> ราคาประเมิน สิ่งปลูกสร้าง หลังหัก ค่าเสื่อม</span>
        </v-col>
        <v-col cols="1" class="pt-2">
          {{ data.buildingPriceMinusDepreciation }}
        </v-col>
        <v-col cols="3" class="pt-2">บาท</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-10">
          <span><b>หมวด ก.</b></span>
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>รวมราคาประเมินของที่ดินและ สิ่งปลูกสร้าง</span>
        </v-col>
        <v-col cols="1" class="pt-2">
          {{ data.priceLandPlusBuilding }}
        </v-col>
        <v-col cols="3" class="pt-2">บาท</v-col>
        <v-col cols="2" class="pt-2">
          <span
            >ราคาประเมิน ของที่ดินและ
            สิ่งปลูกสร้างตามสัดส่วนการใช้ประโยชน์</span
          >
        </v-col>
        <v-col cols="1" class="pt-2">
          {{ data.priceLandPlusBuildingPerProfit }}
        </v-col>
        <v-col cols="3" class="pt-2">บาท</v-col>
        <v-col cols="2" class="pt-2">
          <span>หักมูลค่าฐานภาษีที่ได้รับยกเว้น</span>
        </v-col>
        <v-col cols="1" class="pt-2">
          {{ data.priceExceptTax }}
        </v-col>
        <v-col cols="3" class="pt-2">บาท</v-col>
        <v-col cols="2" class="pt-2">
          <span>คงเหลือราคาประเมิน ทุนทรัพย์ ที่ต้องชำระภาษี </span>
        </v-col>
        <v-col cols="1" class="pt-2">
          {{ data.priceTax }}
        </v-col>
        <v-col cols="3" class="pt-2">บาท</v-col>
        <v-col cols="2" class="pt-2">
          <span>อัตราภาษี (ร้อยละ)</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.taxPercent  }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5"></v-col>
        <v-col cols="2" class="py-4">
          <v-btn color="#744D9c" style="color:white;" @click="backpds1()">กลับ</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
// import axios from "axios";
import { Decode } from "@/services";
export default {
  data() {
    return {
      list: [],
      count: 0,
      checkuser: [],
      data: [],
    };
  },
  created() {
    this.checkuser = JSON.parse(Decode.decode(localStorage.getItem("user")));
    this.data = JSON.parse(Decode.decode(localStorage.getItem("data")));
    console.log("data", this.data); // this.pds1data คือค่าที่ส่งมาจาก แว่นขยาย
    // this.getviewallpds1();
  },
  methods: {
    backpds1() {
      this.$router.push("/pds1");
    },
    //  async getviewallpds1() {
    //    console.log("11111");
    //     const auth = {
    //       headers: { Authorization: `Bearer` + " " + this.checkuser.token },
    //     };

    //     // console.log(token);
    //     const response = await axios.get(
    //       `${process.env.VUE_APP_API}/pds1s`,
    //       auth, this.pds1id
    //     );
    //     // console.log("ดาต้าที่ออกมา", response.data.data);
    //     this.list = response.data.data;
    //     console.log("lsit", this.list);

    //     this.list.forEach(element => {

    //     });
    //     for (let i in this.list) {
    //       this.list[i].count = parseInt(i) + 1;
    //       //console.log(this.list);
    //     }
    //   },
  },
};
</script>
<style scoped>
.f-right {
  float: right;
}
</style>
